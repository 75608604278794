<template>
  <b-container v-if="!loadingProductInfo">
    <product-item-description
    :prodInfo="productInfo"
    ></product-item-description>
    <b-card class="mt-5 mb-2 px-2 iq-border-radius-20">
      <template v-slot:header>
        <h2 class="py-1 text-primary font-weight-bold main-title-details">{{ $t('marketplace.Specifications') }}</h2>
      </template>
      <ul class="product-item-details">
        <li class="text-muted font-size-20">
          {{productInfo.content}}
        </li>
      </ul>
    </b-card>
    <b-card v-if="productInfo.reviews.length > 0" class="mt-5 mb-2 px-2 iq-border-radius-20">
      <template v-slot:header>
        <div class="d-flex justify-content-between align-items-center">
        <h2 class="main-title-details py-1 text-primary font-weight-bold">{{ $t('marketplace.clientsComments') }}</h2>
        <b-button
            class="d-flex justify-content-between align-items-center bg-transparent border-0 see-more"
        >
          <span class="main-see-more-detail text-primary ml-1">{{ $t('marketplace.seeAll') }}</span>
          <i class="las la-caret-left text-primary font-size-26"></i>
<!--          <i class="las la-shopping-cart ml-0 mr-2 font-size-26 mr-5"></i>-->
        </b-button>
        </div>
      </template>
      <b-row class="justify-content-between">
        <b-col md="3">
          <div class="d-flex flex-column justify-content-start">
            <div class="rate-header d-flex flex-column justify-content-center align-items-center mb-4">
            <h1 class="font-weight-bold text-primary mt-3 mb-3">4/5</h1>
            <div class="star-icons star-product d-flex justify-content-center align-items-center mb-4">
              <img
                  class="inline-flex mr-1"
                  :src="require('@/assets/images/ibbil/star.svg')"
              />
              <img
                  class="inline-flex mr-1"
                  :src="require('@/assets/images/ibbil/star.svg')"
              />
              <img
                  class="inline-flex mr-1"
                  :src="require('@/assets/images/ibbil/star.svg')"
              />
              <img
                  class="inline-flex mr-1"
                  :src="require('@/assets/images/ibbil/star.svg')"
              />
              <img
                  class="inline-flex mr-1"
                  :src="require('@/assets/images/ibbil/star.svg')"
              />
            </div>
            <h3 class="text-primary">107</h3>
            <p class="font-size-24 text-primary mb-3">{{ $t('marketplace.validProductRate') }}</p>
            </div>
          <div class="rate-progress d-flex flex-column justify-content-start mb-4">
            <div class="rate-details d-flex justify-content-between align-items-center w-100" v-for="(item , key) in rateDetails" :key="key">
              <p class="text-secondary font-size-18 m-0 p-0">{{item.id}}</p>
              <img
                  class="inline-flex star-icon-rate"
                  :src="item.img"
              />
              <p class="text-secondary font-size-20 m-0 p-0">({{item.num}})</p>
              <div class="iq-progress-bar-linear d-inline-block w-50">
                <div class="iq-progress-bar">
                  <span :data-value="item.value" class="bg-warning" style="transition: width 2s ease 0s; width: 55%;"></span>
                </div>
              </div>
            </div>
          </div>
          </div>
        </b-col>
        <b-col md="8">
        <div class="d-flex flex-column justify-content-start">
          <p class="main-users-comment-order text-primary mb-3">{{ $t('marketplace.userProductComments') }}</p>
          <div class="rate-header d-flex flex-column justify-content-start p-3 mb-3" v-for="(comment , key) in clientComment" :key="key">
            <div class="star-icons star-product-comment d-flex  align-items-center mb-3">
              <img
                  class="inline-flex mr-1"
                  :src="require('@/assets/images/ibbil/star.svg')"
              />
              <img
                  class="inline-flex mr-1"
                  :src="require('@/assets/images/ibbil/star.svg')"
              />
              <img
                  class="inline-flex mr-1"
                  :src="require('@/assets/images/ibbil/star.svg')"
              />
              <img
                  class="inline-flex mr-1"
                  :src="require('@/assets/images/ibbil/star.svg')"
              />
              <img
                  class="inline-flex mr-1"
                  :src="require('@/assets/images/ibbil/star.svg')"
              />
            </div>
            <h4 class="text-primary font-weight-bold mb-2">{{comment.commentName}}</h4>
            <p class="text-muted font-size-16 mb-3">{{comment.commentDetails}}</p>
            <div class="d-flex justify-content-between align-items-center flex-md-row flex-column mb-0">
              <div class="comment-details m-0 p-0">
                <p>{{ $t('marketplace.commentedAt') }} <span>{{comment.commentDay}} </span><span>{{comment.commentDate}}</span><span>{{comment.commentYear}}</span>بواسطة <span class="text-primary font-weight-bold mr-2"> {{comment.userComment}} </span></p>
              </div>
              <div class="d-flex align-items-center flex-row-reverse gap_1">
                <img :src="require('@/assets/images/ibbil/permissions.svg')" alt="permissions" />
                <p class="text-success font-size-16  p-0 m-0">{{ $t('marketplace.validProductPayment') }}</p>
              </div>
            </div>
          </div>
        </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card  v-if="productInfo.product_faqs.length > 0" class="mt-5 mb-2 px-2 iq-border-radius-20">
      <template v-slot:header>
        <h2 class="py-1 text-primary font-weight-bold main-title-details">{{ $t('marketplace.mainQuestions') }}</h2>
      </template>
      <ul v-for="(faqs , key ) in productInfo.product_faqs" :key="key" class="product-item-details">
        <li>
          <h4 class="text-primary font-size-20">
            {{faqs.question}}
          </h4>
          <p class="text-muted font-size-20">{{faqs.answer}}</p>
        </li>
      </ul>
    </b-card>
    <b-card  v-if="productInfo.related_products.length > 0" class="mt-5 mb-2 px-2 iq-border-radius-20">
      <template v-slot:header>
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="py-1 text-primary font-weight-bold main-title-details">{{ $t('marketplace.mayYouLike') }}</h2>
          <b-button
              class="d-flex justify-content-between align-items-center bg-transparent border-0 see-more"
          >
            <span class="main-see-more-detail text-primary ml-1">{{ $t('marketplace.seeAll') }}</span>
            <i class="las la-caret-left text-primary font-size-26"></i>
            <!--          <i class="las la-shopping-cart ml-0 mr-2 font-size-26 mr-5"></i>-->
          </b-button>
        </div>
      </template>
      <div class="position-relative">
        <div class="arrows arrow-category" v-if="productInfo.related_products.length > 4">
        <span class="swiper-button-next top-40 position-absolute left-20">
                <div class="icon-category d-flex justify-content-center align-items-center">
                  <i class="las la-angle-left"></i>
                </div>
              </span>
        </div>
          <Swiper :id="`post-slider-list-of-stores`"  :options="swiperOptions" :pagination="false">
            <swiperSlide class="" v-for="item in productInfo.related_products" :key="item.id">
              <router-link :to="{name:'productDetails', params:{productId: item.id}}">
                <product-card
                    :productItem ="item"
                >
                </product-card>
              </router-link>
            </swiperSlide>
          </Swiper>
      </div>
    </b-card>
  </b-container>
  <b-container v-else class="d-flex align-items-center justify-content-center mt-5">
    <b-spinner large type="grow" variant="primary"></b-spinner>
  </b-container>
</template>
<script>
import productItemDescription from '@/modules/servicePages/marketplace/components/productItemDescription'
import productCard from '../components/productCard'
import marketPlace from '@/modules/servicePages/marketplace/services/marketplace'
export default {
  name: 'productDetails',
  components: { productItemDescription, productCard },
  data () {
    return {
      rateDetails: [
        {
          id: 5,
          img: require('@/assets/images/ibbil/star.svg'),
          num: 76,
          value: 75
        },
        {
          id: 4,
          img: require('@/assets/images/ibbil/star.svg'),
          num: 22,
          value: 55
        },
        {
          id: 3,
          img: require('@/assets/images/ibbil/star.svg'),
          num: 12,
          value: 38
        },
        {
          id: 2,
          img: require('@/assets/images/ibbil/star.svg'),
          num: 18,
          value: 45
        },
        {
          id: 1,
          img: require('@/assets/images/ibbil/star.svg'),
          num: 7,
          value: 13
        }
      ],
      clientComment: [
        {
          id: 0,
          commentName: 'منتج جميل',
          commentDetails: 'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.',
          commentDay: 7,
          commentDate: 'مارس',
          commentYear: 2021,
          userComment: 'Nawwaf'
        },
        {
          id: 1,
          commentName: 'منتج رائع',
          commentDetails: 'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.',
          commentDay: 22,
          commentDate: 'أغسطس',
          commentYear: 2022,
          userComment: 'Ahmed'
        }
      ],
      swiperOptions: {
        centeredSlides: false,
        spaceBetween: 10,
        autoplay: false,
        slidesPerView: 3.5,
        dir: 'ltr',
        pagination: {
          clickable: true,
          el: '.swiper-pagination',
          type: 'bullets'
        },
        loop: false,
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 15
          },
          480: {
            slidesPerView: 1.5,
            spaceBetween: 15,
            centeredSlides: true
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
            centeredSlides: false
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 30,
            centeredSlides: false
          }
        },
        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      productInfo: null,
      loadingProductInfo: true
    }
  },
  watch: {
    '$route' () {
      this.getProductInfo()
    }
  },
  methods: {
    // getData () {
    //   console.log(this.products, this.$route.params.id)
    //   this.products.filter(item => {
    //     // eslint-disable-next-line eqeqeq
    //     return item.id == this.$route.params.id
    //   })
    // },
    getProductInfo () {
      this.loadingProductInfo = true
      marketPlace.getProductDetail(this.$route.params.productId).then(res => {
        this.productInfo = res.data
      }).finally(() => {
        this.loadingProductInfo = false
      })
    }
  },
  created () {
    this.getProductInfo()
  }
}
</script>

<style>
.product-item-details li{
  list-style-type: disc;
  padding-bottom: 10px;
}
.star-product img{
width: 26px;
}
.star-product-comment{
  width: 20px;
}
.rate-header{
  width: 100%;
  background: #FBFBFB;
  border-radius: 8px;
}
.star-icon-rate{
  width: 17px;
  height: 16px;
}
.main-title-details{
  font-size: 25px !important;
 }
.main-see-more-detail{
  font-size: 20px !important;
}
.main-users-comment-order{
  font-size: 22px !important;
}
@media (max-width: 768px) {
  h2{
    font-size: 22px !important;
  }
  .see-more i{
    font-size: 18px !important;
  }
  .product-item-details li{
    font-size: 16px !important;
  }
  .main-title-details{
    font-size: 20px !important;
  }
  .main-see-more-detail{
    font-size: 15px !important;
  }
  .main-users-comment-order{
    font-size: 18px !important;
  }
}
</style>
