<template>
  <b-card class="mt-5 iq-border-radius-20">
    <b-row class=" d-flex justify-content-end ">
      <div @click="goBackToStore" class="return-btn">
        <b-button variant="primary" class="text-white font-size-20">
          {{ $t('marketplace.backToProfile') }}
          <span><i class="las la-angle-left"></i></span>
        </b-button>
    </div>
    </b-row>
    <b-row>
      <b-col lg="6" v-if="prodInfo.product_images">
        <slider-thumbs
            :images="[prodInfo.featured_image, ...prodInfo.product_images]"
        ></slider-thumbs>
      </b-col>
      <b-col lg="6" class="d-flex flex-column justify-content-start">
        <div class="product-item-title mb-2">
          <h2 class="text-primary font-weight-bold">{{ prodInfo.name }}</h2>
        </div>
        <div class="product-item-description">
          <h6 class="text-muted font-size-20">{{ prodInfo.description.length  >= 130 ? prodInfo.description.slice(0, 130)+'....'  : prodInfo.description}}</h6>
        </div>
        <div class="star-icons mb-2">
          <span class="text-secondary font-size-18 p-0 m-0">(15 reviews)</span>
          <img
              class="inline-flex mr-1"
              :src="require('@/assets/images/ibbil/star.svg')"
          />
          <img
              class="inline-flex mr-1"
              :src="require('@/assets/images/ibbil/star.svg')"
          />
          <img
              class="inline-flex mr-1"
              :src="require('@/assets/images/ibbil/star.svg')"
          />
          <img
              class="inline-flex mr-1"
              :src="require('@/assets/images/ibbil/star.svg')"
          />
          <img
              class="inline-flex mr-1"
              :src="require('@/assets/images/ibbil/star.svg')"
          />
        </div>
        <b-col class="product-item-price-info mt-2">
          <div>
          <div v-if="prodInfo.flash_sales.length > 0">
            <h4 class="text-primary">{{ $t('marketplace.beforePrice') }}</h4>
            <p class="product-item-offer font-size-22 m-0">{{prodInfo.price_info.final_price + prodInfo.price_info.discount}}<span class="font-size-22"> ر.س</span></p>
          </div>
          <p class="text-primary font-size-24 m-0 p-0">{{ $t('marketplace.priceNow') }}</p>
          <p v-if="prodInfo.flash_sales.length >0" class="product-item-price text-primary font-size-30 m-0 p-0">{{ prodInfo.price_info.final_price }}<span class="font-size-26"> ر.س</span></p>
          <p v-else class="product-item-price text-primary font-size-30 m-0 p-0">{{ prodInfo.price_info.final_price }}<span class="font-size-26"> ر.س</span></p>
          <div class="product-item-offer-info d-flex justify-content-between align-items-center mt-0 mb-3">
            <p class="text-primary font-size-20 mt-1 mb-0">{{ $t('marketplace.quantity') }}</p>
            <div v-if="prodInfo.flash_sales.length > 0" class="d-flex align-items-center justify-content-between mt-0">
              <div class="offer-price-percent d-flex align-items-center justify-content-between ml-2 px-3 py-1">
                <span class="font-size-18">{{ $t('marketplace.discount') }} {{ prodInfo.flash_sales[prodInfo.flash_sales.length - 1].percentage }}%</span>
              </div>
              <p class="text-primary font-size-20 m-0 ml-3">{{ $t('marketplace.savedPrice') }} :<span class="font-weight-bold font-size-20">{{prodInfo.price_info.discount}} ر.س</span></p>
            </div>
          </div>
          <b-row>
            <b-col v-if="!check(prodInfo)" cols="5" lg="2" class="product-item-calculate d-flex justify-content-between align-items-center">
              <b-button @click="increaseQuantity" class="bg-white m-0 p-0 text-primary border-0 "><i class="las la-plus m-0 text-primary font-size-20"></i></b-button>
              <span class="font-size-20 m-0 p-0 text-primary ">{{ quantity }}</span>
              <b-button @click="decreaseQuantity" class="bg-white m-0 p-0  text-primary border-0 "><i class="las la-minus m-0 text-primary font-size-20"></i></b-button>
            </b-col>
            <b-col cols="12" lg="10">
              <b-row class="mt-2">
                <b-col cols="12" md="6">
<!--                  <router-link :to="{name:'cart', params:{id:'1'}}">-->
                    <b-button
                        v-if="!check(prodInfo)"
                        @click="prodInfo.flash_sales.length > 0 ? prodInfo.price =  prodInfo.flash_sales[prodInfo.flash_sales.length - 1].price : '' ; addToCart(prodInfo)"
                        variant="primary"
                        class="d-flex justify-content-between align-items-center py-2 min-button"
                    >
                      <span class="font-size-17 pl-5 text-white">{{ $t('marketplace.addToCart') }} </span>
                      <i class="las la-shopping-cart font-size-26"></i>
                    </b-button>
                    <b-button
                      v-else
                      variant="outline-primary"
                      class="d-flex justify-content-between align-items-center py-2 min-button"
                  >
                    <span  class="font-size-17 pl-5">يوجد فى السلة</span>
                    <i class="las la-shopping-cart font-size-26"></i>
                  </b-button>
<!--                  </router-link>-->
                </b-col>
                <b-col cols="12" md="6">
                  <b-button
                      v-if="!checkFavourite(prodInfo)"
                      @click="addToFavourite(prodInfo)"
                      variant="outline-primary"
                      class="d-flex justify-content-between align-items-center py-2 min-button"
                  >
                    <span class="font-size-18 pl-2">{{$t('marketplace.addToFavourite')}}</span>
                    <i class="las la-heart font-size-24"></i>
                  </b-button>
                  <b-button
                      v-else
                      @click="deleteProductFavourite(prodInfo)"
                      variant="outline-warning"
                      class="d-flex justify-content-between align-items-center py-2 min-button"
                  >
                    <span class="font-size-18 pl-2">{{$t('marketplace.removeFromFavourite')}}</span>
                    <i class="las la-heart font-size-24"></i>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          </div>
        </b-col>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import sliderThumbs from '@/components/core/slider/sliderThumbs'
import productFunctions from '@/mixins/productFunctions'
import { core } from '@/config/pluginInit'
import marketPlace from '@/modules/servicePages/marketplace/services/marketplace'
export default {
  name: 'productItemDescription',
  props: ['prodInfo'],
  components: { sliderThumbs },
  mixins: [productFunctions],
  data () {
    return {
      quantity: 1,
      img: [
        {
          image: require('@/assets/images/ibbil/ibbil-cover.jpg')
        },
        {
          image: require('@/assets/images/ibbil/images/camel.png')
        },
        {
          image: require('@/assets/images/ibbil/images/desert_camel_store.png')
        },
        {
          image: require('@/assets/images/ibbil/images/card1.png')
        },
        {
          image: require('@/assets/images/ibbil/ibbil-cover.jpg')
        }
      ]
    }
  },
  computed: {
    totalPrice () {
      return this.prodInfo.tax ? this.prodInfo.price * this.prodInfo.tax.percentage / 100 : this.prodInfo.price
    }
  },
  methods: {
    check (payload) {
      return this.$store.getters['cart/checkIfProductInCart'](payload)
    },
    addToCart (product) {
      this.$store.dispatch('cart/addToCart', { ...product, quantityToBuy: this.quantity })
      const carts = this.$store.getters['cart/getProductCart']
      marketPlace.postProductInCart({ carts: carts }).then(res => {
        core.showSnackbar('success', 'تم اضافة المنتج فى السلة')
      })
        .catch(err => {
          console.log((err))
        })
    },
    goBackToStore () {
      this.$router.go(-1)
    },
    increaseQuantity () {
      this.quantity++
    },
    decreaseQuantity () {
      if (this.quantity === 1) {
        this.quantity = 1
      } else {
        this.quantity--
      }
    },
    // addToFavourite (product) {
    //   this.$store.dispatch('cart/addToFavourite', product)
    // },
    checkFavourite (product) {
      return this.$store.getters['cart/checkProductInFavourite'](product)
    }
    // deleteProductFavourite (payload) {
    //   this.$store.dispatch('cart/deleteProductInFavourite', payload)
    // }
  }
}
</script>

<style>
.product-item-calculate{
  box-shadow: 0px 0px 12px #0000001C;
  border-radius: 13px;
}
.product-item-price-info .product-item-offer{
  margin-left: 12px;
  color: #EC5D61 ;
  text-decoration: line-through #EC5D61 ;
}
.product-item-price-info .offer-price-percent{
  border-radius: 8px;
  background-color: #EC5D61 ;
  color: #FFFFFF;
}
@media (max-width: 768px) {
  .product-item-calculate{
    margin: auto !important;
    width: 100px !important;
  }
  .min-button{
    width: 100%;
    margin-top: 10px !important;
  }
  .return-btn{
    display: none !important;
  }
  .product-item-title{
    margin-top: 15px;
  }
  .offer-price-percent span{
    font-size: 12px !important;
  }
}
</style>
